import React from 'react';
import './App.css';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function TokenBox(props) {
  const [rerender, setRerender] = React.useState(0);
  function generateTokenSpan(tokenId, tokenObject) {
    // Get the token state
    if (tokenObject.effect) {
      return (<span key={tokenId} style={{opacity: 1.0}}>{tokenObject.char}</span>)
    } else {
      return (<span key={tokenId} style={{opacity: 0.3}}>{tokenObject.char}</span>)
    }
  }

  function generateTokens() {
    var numWords = Object.keys(props.words).length;
    var numWordsToBold = Math.max(1, getRandomInt(Math.min(numWords, Math.log2(10 * numWords))));
    var wordsToBold = {};
    for (var i = 0; i < numWordsToBold; i++) {
      wordsToBold['' + getRandomInt(numWords)] = true;
    }
    for (const [wordId, tokenIds] of Object.entries(props.words)) {
      if (wordsToBold[wordId]) {
        for (var i = 0; i < tokenIds.length; i++) {
          var tokenId = tokenIds[i];
          props.tokens[tokenId].effect = 'bold';
        }
      } else {
        for (var i = 0; i < tokenIds.length; i++) {
          var tokenId = tokenIds[i];
          props.tokens[tokenId].effect = undefined;
        }
      }
    }
    var inorderTokens = Object.keys(props.tokens).map((token) => false);
    for (const [tokenId, tokenObject] of Object.entries(props.tokens)) {
      inorderTokens[tokenObject.index] = generateTokenSpan(tokenId, tokenObject);
    }
    return inorderTokens;
  }

  React.useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setRerender(rerender + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [rerender]);
  return (
    <div className="App-header">
      { generateTokens() }
    </div>
  );
}

function App() {
  const [inputText, setInputText] = React.useState('');

  function handleSubmit(event) {
    event.preventDefault();
  }

  function onEnterPress(e) {
    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setInputText(e.currentTarget.value);
    }
  }

  const words = inputText.split('\s+');
  var text = words.join(' ');
  var initialWords = {};
  var initialTokens = {};
  var wordIndex = 0;
  var tokenIndex = 0;
  var currentWord = [];
  for (var i = 0; i < text.length; i++) {
    var chr = text[i];
    initialTokens[tokenIndex] = {'char': chr, 'index': i};
    if (chr === ' ') {
      initialWords[wordIndex] = currentWord;
      wordIndex += 1;
      currentWord = [];
    } else {
      currentWord.push(tokenIndex);
    }
    tokenIndex += 1;
  }
  if (currentWord.length > 0) {
    initialWords[wordIndex] = currentWord;
  }


  return (
    <div className="App">
        { inputText === '' ?
            <form onSubmit={handleSubmit}>
              <textarea className="App-textarea" autoFocus name="flavortext" onKeyDown={onEnterPress}/>
            </form>
            : <TokenBox tokens={initialTokens} words={initialWords} /> }
    </div>
  );
}

export default App;
